import {ref, onMounted} from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import {api} from '@/services/api'

export default function useProductLower() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'product_id',
            width: '50',
        },
        {
            text: 'คลังสินค้า',
            value: 'store_name',
            width: '120',
            align: 'center',
        },
        {
            text: 'รหัสสินค้า',
            value: 'product_code',
            width: '120',
            align: 'center',
        },
        {
            text: 'ชื่อสินค้า',
            value: 'product_name',
            width: '200',
        },
        {
            text: 'หมวดหมู่สินค้า',
            value: 'product_name',
            width: '150',
        },
        {
            text: 'น้ำหนัก',
            value: 'product_weight',
            width: '100',
            align: 'end',
        },
        {
            text: 'หน่วย',
            value: 'product_unit',
            width: '100',
        },
        {
            text: 'คงเหลือ',
            value: 'store_product_balance',
            width: '100',
            align: 'end',
        },
        {
            text: ' ร้านค้า',
            value: 'shop_id',
            width: '100',
            align: 'center',
        },

    ])
    const itemsPerPage = ref(10)
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const status = ref('1')
    const options = ref([{
        text: 'ทั้งหมด',
        value: '',
    },
        {
            text: 'ร้านค้า',
            value: '2',
        },
        {
            text: 'Data center',
            value: '1',
        }
    ])
    const selection = ref('')

    const isShowDetail = ref(false)
    const changeStatus = (id) => {
        status.value = id
        getProducts()
    }

    const getProducts = () => {
        loading.value = true
        api.get({
            path: '/reportProductLower',
            param: `?search=${textSearch.value}&shopGroup=${selection.value}`,
        }).then(({data}) => {
            dataList.value = data
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    onMounted(() => {
        getProducts()
    })

    return {
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isShowDetail,
        isEdit,
        selection,
        options,
        dataEdit,
        footer,
        isUpdateStatus,
        status,
        dataList,
        loading,
        changeStatus,
        getProducts,
    }
}
