<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <span>
          ข้อมูลสินค้าใกล้หมด
        </span>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getProducts'
                        label='ค้นหาสินค้า'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.product_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.shop_id`]='{ item }'>
          <v-chip
              :color="item.shop_id == 1?'primary':'info'"
              :class="item.shop_id==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
              small
          >
            {{ item.shop_id == 1 ? `Data center` : `ร้านค้า` }}
          </v-chip>
        </template>
        <template #[`item.map_shop_product_active`]='{ item }'>
          <StatusBlock :status='String(item.map_shop_product_active)'/>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import useProductLower from './useProductLower'
import FilterStatus from '@/components/FilterStatus'
import StatusBlock from '@/components/StatusBlock'
import {mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiTextBoxCheck} from '@mdi/js'

export default {
  props: {},
  components: {
    FilterStatus,
    StatusBlock,
  },
  setup(_, {root, emit}) {
    return {
      ...useProductLower(),
      mdiCheck,
      mdiTextBoxCheck,
      mdiPencilOutline,
      mdiDeleteOutline,
    }
  },

}
</script>

<style scoped>

</style>
